/**
 * Gantt configuration export
 */

// Bryntum umd lite bundle comes without polyfills to support Angular's zone.js
import { AssignmentModel } from 'bryntum-gantt/gantt.lite.umd.js';

const imgFolderPath = 'assets/images/users/';

const ganttConfig = {
    startDate               : '2019-01-12',
    endDate                 : '2020-03-24',
    resourceImageFolderPath : 'assets/images/users/',
    columns                 : [
        { type : 'name', width : 250, text : "Commande" },
        { type : 'startdate', text:"debut" },
        { type : 'duration' , text:"durée"},
    ],

    subGridConfigs : {
        locked : {
            flex : 3
        },
        normal : {
            flex : 4
        }
    },

    columnLines : true,

    features : {
        rollups : {
            disabled : true
        },
        baselines : {
            disabled : true
        },
        taskMenu : {
            // Our items is merged with the provided defaultItems
            // So we add the provided convertToMilestone option.
            items : {
                convertToMilestone : true
            },
            processItems({ taskRecord, items }) {
                if (taskRecord.isMilestone) {
                    items.convertToMilestone = false;
                }
            }
        },
        filter         : true,
        dependencyEdit : false,
        timeRanges     : {
            showCurrentTimeLine : true
        },
        labels : {
            left : {
                field  : 'name',
                editor : {
                    type : 'textfield'
                }
            }
        }
    }
};

export default ganttConfig;
